import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import sanitizeHtml from 'sanitize-html';
import ArticleIcon from '@mui/icons-material/Article';


// const formatDate = (date) => {
//     if (!date || isNaN(new Date(date).getTime())) return 'Data inválida';
//     const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
//     return new Date(date).toLocaleString('pt-BR', options);
// };

const NoteListItem = ({ note, isSelected, onClick }) => {
    const getTextFromHtml = (html) => {
        // Remove as tags <span> usando sanitizeHtml
        const cleanHtml = sanitizeHtml(html, {
            allowedTags: sanitizeHtml.defaults.allowedTags.filter(tag => tag !== 'span'), // Remove 'span' da lista de tags permitidas
            allowedAttributes: {}, // Remove todos os atributos (se necessário)
        });

        const regex = /<[^>]+>(.*?)<\/[^>]+>/g; // Captura o conteúdo dentro das tags
        let match;

        while ((match = regex.exec(cleanHtml)) !== null) {
            const content = match[1].trim(); // Conteúdo entre as tags
            if (content.length > 3) return content;
        }

        return ''; // Retorna string vazia se não encontrar conteúdo adequado
    };

    const title = getTextFromHtml(note.content) || 'Sem título'; // Título padrão se não encontrar conteúdo

    // Limita o título a 60 caracteres
    const truncatedTitle = title.length > 60 ? title.substring(0, 57) + '...' : title;

    return (
        <Card
            sx={{
                mb: .5,
                // borderRadius: 0,
                cursor: 'pointer',
                bgcolor: isSelected ? 'action.selected' : 'background.paper',
                color: isSelected ? 'white' : 'action.default',
                '&:hover': {
                    backgroundColor: 'action.active',
                    color: 'white',
                },
            }}
            onClick={() => onClick(note)}
        >
            <CardContent sx={{ p: 1, '&:last-child': { pb: 1 }, textAlign: 'left', display: 'flex', alignContent: 'center' }}>
                <ArticleIcon />
                <Typography variant="body1" sx={{ ml: 1, mt: "3px" }}>
                    {truncatedTitle}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default NoteListItem;
