import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Container,
  Grid,
  Typography,
  Link,
} from "@mui/material";

const ImageMetadataAnalyzer = () => {
  const [metadata, setMetadata] = useState([]);
  const [error, setError] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageCatalog, setImageCatalog] = useState([]);
  const [selectedCatalogItem, setSelectedCatalogItem] = useState(null);

  // Load catalog from localStorage on initial render
  useEffect(() => {
    const savedCatalog = localStorage.getItem("imageCatalog");
    if (savedCatalog) {
      try {
        const parsedCatalog = JSON.parse(savedCatalog);
        setImageCatalog(parsedCatalog);
        if (parsedCatalog.length > 0) {
          const lastItem = parsedCatalog[parsedCatalog.length - 1];
          setSelectedCatalogItem(lastItem);
          setMetadata(lastItem.metadata);
          setImageSrc(lastItem.imageUrl);
        }
      } catch (err) {
        console.error("Error parsing saved catalog", err);
      }
    }
  }, []);

  // Effect to add drag and drop listeners to the entire window
  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (file) {
        processDroppedFile(file);
      }
    };

    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("drop", handleDrop);

    return () => {
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  // Function to resize the image before converting to Base64
  const resizeImage = (file, maxSize) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Define new dimensions
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL(file.type)); // Base64 encoded image
        };
        img.src = event.target.result;
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const processDroppedFile = (file) => {
    setError(null);

    if (file && file.type === "image/png") {
      const reader = new FileReader();

      reader.onload = () => {
        const arrayBuffer = reader.result;
        const metadata = extractPNGMetadata(arrayBuffer);

        if (metadata) {
          // Resize image and convert to Base64
          resizeImage(file, 240).then((imageBase64) => {
            const timestamp = new Date().toISOString();

            const catalogItem = {
              id: timestamp,
              filename: file.name,
              path: file.path || "Unknown",
              imageUrl: imageBase64, // Base64 encoded image
              metadata: metadata,
              createdAt: file.lastModifiedDate, // Data de criação do arquivo
              analyzedAt: new Date().toISOString(), // Quando a imagem foi analisada
            };
            

            // Update the catalog, adding the new item without replacing the existing ones
            const updatedCatalog = [...imageCatalog, catalogItem];
            setImageCatalog(updatedCatalog);

            // Save to localStorage the full updated catalog
            localStorage.setItem("imageCatalog", JSON.stringify(updatedCatalog));

            // Set current image and metadata
            setMetadata(metadata);
            setImageSrc(imageBase64);

            // Select the most recently added item
            setSelectedCatalogItem(catalogItem);
          });
        } else {
          setError("Nenhum metadado PNG relevante encontrado.");
        }
      };

      reader.onerror = () => {
        setError("Erro ao ler a imagem");
      };

      reader.readAsArrayBuffer(file);
    } else {
      setError("Por favor, arraste um arquivo PNG válido.");
    }
  };

  const extractPNGMetadata = (arrayBuffer) => {
    const dataView = new DataView(arrayBuffer);
    let offset = 8; // Skip PNG signature

    const metadata = [];

    while (offset < dataView.byteLength) {
      const chunkLength = dataView.getUint32(offset);
      const chunkType = String.fromCharCode(
        dataView.getUint8(offset + 4),
        dataView.getUint8(offset + 5),
        dataView.getUint8(offset + 6),
        dataView.getUint8(offset + 7)
      );

      if (chunkType === "tEXt") {
        const chunkData = new TextDecoder().decode(
          new Uint8Array(arrayBuffer, offset + 8, chunkLength)
        );
        const [key, value] = chunkData.split("\0"); // Key and value are separated by null byte
        metadata.push({ key, value });
      }

      offset += 12 + chunkLength; // 12 = 4 (length) + 4 (type) + 4 (CRC)
    }

    return metadata.length ? metadata : null;
  };

  const handleCatalogItemSelect = (item) => {
    setSelectedCatalogItem(item);
    setMetadata(item.metadata);
    setImageSrc(item.imageUrl);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Analisador de Metadados de Imagem
          </Typography>

          <Box
            sx={{
              border: "2px dashed #ccc",
              borderRadius: "10px",
              padding: "20px",
              textAlign: "center",
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {imageSrc ? (
              <img
                src={imageSrc}
                alt="Imagem PNG"
                style={{
                  maxWidth: "100%",
                  maxHeight: "400px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <Typography variant="body1" color="textSecondary">
                Arraste e solte uma imagem PNG em qualquer lugar do navegador
              </Typography>
            )}
          </Box>

          {error && <Typography color="error">{error}</Typography>}

          {metadata.length > 0 && (
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Chave</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Valor</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metadata.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.key}</TableCell>
                      <TableCell><div style={{  maxHeight: "8em", overflow: "auto", whiteSpace: "pre-wrap", wordWrap: "break-word", whiteSpaceTrim: "all", width: "100%", fontSize: "12px", fontFamily: "monospace" }}>{item.value}</div></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            Catálogo de Imagens
          </Typography>

          <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>
          <strong>Arquivo</strong>
        </TableCell>
        <TableCell>
          <strong>Criação do Arquivo</strong>
        </TableCell>
        <TableCell>
          <strong>Data de Análise</strong>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {imageCatalog.map((item) => (
        <TableRow
          key={item.id}
          onClick={() => handleCatalogItemSelect(item)}
          style={{ cursor: "pointer" }}
        >
          <TableCell>
            <Link href={item.imageUrl} target="_blank" rel="noopener noreferrer">
              {item.filename}
            </Link>
          </TableCell>
          <TableCell>{new Date(item.createdAt).toLocaleString()}</TableCell>
          <TableCell>{new Date(item.analyzedAt).toLocaleString()}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

        </Grid>
      </Grid>
    </Container>
  );
};

export default ImageMetadataAnalyzer;
