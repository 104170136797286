import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Typography,
    IconButton,
    Card,
    CardContent,
    Menu,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Tooltip,
    Divider,
    Chip,
} from "@mui/material";
import { LocalOffer as TagIcon } from "@mui/icons-material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Save";
import { format } from "date-fns";
import sanitizeHtml from 'sanitize-html';
import { useMediaQuery } from '@mui/material';
import TagManager from '../../Components/TagManager/TagManager'
import TagAutocomplete from "../../Components/TagManager/TagAutocomplete";

const sanitizeOptions = {
    allowedTags: [
        'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'ul', 'ol', 'li', 'br', 'strong', 'em',
        'u', 'span', 'blockquote'
    ],
    allowedAttributes: {},
    transformTags: {
        'div': 'p'
    }
};

const NoteViewer = ({
    note,
    handleUpdateNote,
    handleNoteChange,
    handleDeleteNote,
    spellCheckEnabled,
    onSaveShortcut,
    tagManagerOpen,
    setTagManagerOpen,
    handleTagUpdate,
    isSaving,
    availableTags,
    onTagsChange,
}) => {
    const editorRef = useRef(null);
    const [originalContent, setOriginalContent] = useState("");
    const [hasChanges, setHasChanges] = useState(false);
    const previousNoteIdRef = useRef(note?.id);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [lastSelection, setLastSelection] = useState(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [tagInputActive, setTagInputActive] = useState(false);
    const [currentTagInput, setCurrentTagInput] = useState('');

    const sanitizeContent = (content) => {
        return sanitizeHtml(content, sanitizeOptions);
    };

    const handleKeyDown = (e) => {
        if (tagInputActive && e.key === 'Enter') {
          e.preventDefault();
          const selectedTag = tagSuggestions[0]?.label;
          if (selectedTag) {
            insertTagAtCursor(selectedTag);
          }
        }
      };

      const insertTagAtCursor = (tagText) => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const startNode = range.startContainer;
        
        // Replace partial tag with complete tag
        const text = startNode.textContent;
        const tagStart = text.lastIndexOf('#');
        const beforeTag = text.substring(0, tagStart);
        const afterTag = text.substring(range.startOffset);
        
        startNode.textContent = beforeTag + tagText + ' ' + afterTag;
        setTagInputActive(false);
      };
    
      const handleInput = (e) => {
        const text = e.target.textContent;
        const match = text.match(/#[a-zA-Z0-9]*$/);
        
        if (match) {
          const partial = match[0].slice(1);
          const filtered = availableTags.filter(tag => 
            tag.label.toLowerCase().startsWith('#' + partial.toLowerCase())
          );
          
          if (filtered.length) {
            const range = window.getSelection().getRangeAt(0);
            const rect = range.getBoundingClientRect();
            setCursorPosition({ x: rect.left, y: rect.bottom + 5 });
            setTagSuggestions(filtered);
            setTagInputActive(true);
            setCurrentTagInput(partial);
          } else {
            setTagInputActive(false);
          }
        } else {
          setTagInputActive(false);
        }
        
        handleContentChange(text);
      };

      const handleContentInput = (e) => {
        const text = e.target.innerHTML;
        const plainText = e.target.textContent;
        console.log('Current text:', plainText);
        
        const match = plainText.match(/#[a-zA-Z0-9]*$/);
        console.log('Tag match:', match);
        
        if (match) {
          const partial = match[0].slice(1);
          const filtered = availableTags.filter(tag => 
            tag.label.toLowerCase().startsWith('#' + partial.toLowerCase())
          );
          
          if (filtered.length) {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            
            setCursorPosition({ 
              x: rect.left + window.scrollX, 
              y: rect.bottom + window.scrollY + 5 
            });
            setTagSuggestions(filtered);
            setTagInputActive(true);
          }
        } else {
          setTagInputActive(false);
        }
        
        handleNoteChange(text);
      };

    useEffect(() => {
        if (note?.id !== previousNoteIdRef.current) {
            if (editorRef.current) {
                editorRef.current.innerHTML = "";
            }
            setHasChanges(false);
            previousNoteIdRef.current = note?.id;
        }

        if (note?.content && editorRef.current) {
            const sanitizedContent = sanitizeContent(note.content);
            editorRef.current.innerHTML = sanitizedContent;
            setOriginalContent(sanitizedContent);
        }
    }, [note?.id, note?.content]);

    const formatDate = (date) => {
        if (!date) return "";
        return format(
            date instanceof Date ? date : date.toDate(),
            "MMM dd, yyyy HH:mm"
        );
    };

    const normalizeContent = () => {
        if (editorRef.current) {
            const editor = editorRef.current;
            const sanitizedContent = sanitizeContent(editor.innerHTML);
            editor.innerHTML = sanitizedContent;
            handleContentChange();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        const sanitizedContent = sanitizeContent(text);
        document.execCommand('insertHTML', false, sanitizedContent);
    };

    const handleContentChange = () => {
        if (editorRef.current) {
            const currentContent = editorRef.current.innerHTML;
            handleNoteChange(currentContent);
            setHasChanges(currentContent !== originalContent);
        }
    };

    const handleSave = (e) => {
        if (e) {
            e.preventDefault();
        }
        
        if (hasChanges && editorRef.current && !isSaving) {
            const currentContent = editorRef.current.innerHTML;
            if (onSaveShortcut) {
                onSaveShortcut(currentContent);
                setHasChanges(false);
            }
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.ctrlKey && e.key === 's') {
                handleSave(e);
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [hasChanges, onSaveShortcut]);

    const saveCurrentSelection = () => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            if (editorRef.current?.contains(range.commonAncestorContainer)) {
                setLastSelection(range.cloneRange());
            }
        }
    };

    const restoreLastSelection = () => {
        if (lastSelection && editorRef.current) {
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(lastSelection.cloneRange());
        }
    };

    const applyFormat = (command, value = null, e) => {
        e?.preventDefault();
        restoreLastSelection();
        document.execCommand(command, false, value);
        
        if (editorRef.current) {
            editorRef.current.focus();
            handleContentChange();
        }
        
        saveCurrentSelection();
    };

    useEffect(() => {
        const handleSelectionChange = () => {
            if (editorRef.current?.contains(document.activeElement)) {
                saveCurrentSelection();
            }
        };

        document.addEventListener('selectionchange', handleSelectionChange);
        return () => {
            document.removeEventListener('selectionchange', handleSelectionChange);
        };
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteConfirmation = () => {
        setOpenDialog(true);
    };

    const handleDelete = () => {
        handleDeleteNote(note.id);
        setOpenDialog(false);
        handleMenuClose();
    };

    const handleCancelDelete = () => {
        setOpenDialog(false);
    };

    const getCurrentNoteTags = () => {
        if (!note?.tags || !availableTags) return [];
        return availableTags.filter(tag => note.tags.includes(tag.id));
    };

    const FormatButton = ({ command, icon, tooltip, shortcut, value = null }) => (
        <Tooltip 
            title={
                <div>
                    {tooltip}
                    <br />
                    <Typography variant="caption" sx={{ opacity: 0.7 }}>
                        Shortcut: {shortcut}
                    </Typography>
                </div>
            }
            enterDelay={1000}
        >
            <IconButton 
                onMouseDown={(e) => {
                    e.preventDefault();
                    applyFormat(command, value, e);
                }}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );

    if (!note) return null;

    return (
        <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
            <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: 'border-box',
                    mb: 1,
                    mt: 1,
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        pl: 1,
                        pr: 1,
                        py: 1,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>

                    <Tooltip title="Save Changes (Ctrl + S)">
                            <span>
                                <IconButton 
                                    onClick={handleSave}
                                    disabled={!hasChanges || isSaving}
                                    color={hasChanges ? "primary" : "default"}
                                >
                                    <SaveIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem />
                        <FormatButton
                            command="bold"
                            icon={<FormatBoldIcon fontSize="small" />}
                            tooltip="Bold"
                            shortcut="Ctrl + B"
                        />
                        <FormatButton
                            command="italic"
                            icon={<FormatItalicIcon fontSize="small" />}
                            tooltip="Italic"
                            shortcut="Ctrl + I"
                        />
                        <FormatButton
                            command="formatBlock"
                            value="<p>"
                            icon={<Typography variant="small">P</Typography>}
                            tooltip="Paragraph"
                            shortcut="Ctrl + Alt + 0"
                        />
                        <FormatButton
                            command="formatBlock"
                            value="<h1>"
                            icon={<Typography variant="small">H1</Typography>}
                            tooltip="Heading 1"
                            shortcut="Ctrl + Alt + 1"
                        />
                        <FormatButton
                            command="formatBlock"
                            value="<h2>"
                            icon={<Typography variant="small">H2</Typography>}
                            tooltip="Heading 2"
                            shortcut="Ctrl + Alt + 2"
                        />
                        <FormatButton
                            command="formatBlock"
                            value="<h3>"
                            icon={<Typography variant="small">H3</Typography>}
                            tooltip="Heading 3"
                            shortcut="Ctrl + Alt + 3"
                        />
                        <FormatButton
                            command="insertUnorderedList"
                            icon={<FormatListBulletedIcon fontSize="small" />}
                            tooltip="Bullet List"
                            shortcut="Ctrl + Shift + 8"
                        />
                        <FormatButton
                            command="insertOrderedList"
                            icon={<FormatListNumberedIcon fontSize="small" />}
                            tooltip="Numbered List"
                            shortcut="Ctrl + Shift + 7"
                        />
                        
                    </Box>
                    <IconButton onClick={handleMenuOpen}>
                        <MoreVertIcon />
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <MenuItem onClick={normalizeContent}>Resetar Formatações</MenuItem>
                        <Divider />
                        <MenuItem onClick={handleDeleteConfirmation}>Deletar</MenuItem>
                    </Menu>

                    <Dialog open={openDialog} onClose={handleCancelDelete}>
                        <DialogTitle>Confirmar Exclusão</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Tem certeza que deseja excluir esta nota? Esta ação não pode ser
                                desfeita.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancelDelete}>Cancelar</Button>
                            <Button onClick={handleDelete} color="error">
                                Excluir
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <CardContent
                        sx={{
                            flex: 1,
                            p: isMobile ? 0 : 2,
                            display: "flex",
                            justifyContent: "center",
                            height: isMobile ? "100%" : "84vh",
                            overflow: "auto",
                        }}
                    >
                        <div
                            ref={editorRef}
                            contentEditable
                            suppressContentEditableWarning
                            spellCheck={spellCheckEnabled}
                            onInput={handleContentInput}
                            onPaste={handlePaste}
                            onKeyDown={handleKeyDown}
                            onBlur={() => {
                                if (editorRef.current) {
                                    const sanitizedContent = sanitizeContent(editorRef.current.innerHTML);
                                    editorRef.current.innerHTML = sanitizedContent;
                                    handleContentChange();
                                }
                            }}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                minHeight: "200px",
                                whiteSpace: "pre-wrap",
                                margin: isMobile ? "2% 8px 10vh 8px" : "6% 24px 20vh 24px",
                                boxSizing: "border-box",
                                outline: "none",
                                maxWidth: "36em",
                                textAlign: "left",
                                fontSize: "16px",
                                fontWeight: 300,
                                height: "fit-content",
                            }}
                        />
                        {tagInputActive && (
                            <TagAutocomplete 
                            availableTags={tagSuggestions}
                            onSelectTag={insertTagAtCursor}
                            position={cursorPosition}
                            />
                        )}
                    </CardContent>
                </Box>
            </Card>
            <Box sx={{ width: "16em", flexGrow: 0, textAlign: "left", p: 3, pl: 2 }}>
        <Typography variant="caption" color="text.secondary">
            Created by
        </Typography>
        <Typography>{note.creatorName}</Typography>
        
        <Typography variant="caption" color="text.secondary">
            Created at
        </Typography>
        <Typography>{formatDate(note.createdAt)}</Typography>
        
        {note.updatedAt?.length > 0 && (
            <>
                <Typography variant="caption" color="text.secondary">
                    Last updated
                </Typography>
                <Typography>
                    {formatDate(note.updatedAt[note.updatedAt.length - 1])}
                </Typography>
            </>
        )}

        <Box sx={{ mt: 2 }}>
            <Button
                startIcon={<TagIcon />}
                onClick={() => setTagManagerOpen(true)}
                variant="outlined"
                size="small"
                sx={{ mb: 1 }}
            >
                Manage Tags
            </Button>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {getCurrentNoteTags().map(tag => (
                    <Chip
                        key={tag.id}
                        label={tag.label}
                        size="small"
                        color="primary"
                        variant="outlined"
                    />
                ))}
            </Box>
        </Box>

        <TagManager
            open={tagManagerOpen}
            onClose={() => setTagManagerOpen(false)}
            currentUserId={note.creatorId}
            availableTags={availableTags}
            onTagsUpdate={handleTagUpdate}
            selectedTags={getCurrentNoteTags()}
            onTagSelectionChange={onTagsChange}
        />
    </Box>
        </Box>
    );
};

export default NoteViewer;