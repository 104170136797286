import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Chip,
  Box,
  Alert,
  IconButton,
  Typography
} from '@mui/material';
import { Close as CloseIcon, Add as AddIcon } from '@mui/icons-material';
import { collection, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../Config/firebaseConfig';

const TagManager = ({ open, onClose, currentUserId, availableTags, onTagsUpdate }) => {
  const [newTag, setNewTag] = useState('');
  const [error, setError] = useState('');

  const validateTag = (tag) => {
    if (!tag.startsWith('#')) return 'Tag must start with #';
    if (/[^a-zA-Z0-9#]/.test(tag)) return 'Only letters and numbers allowed';
    if (tag.includes(' ')) return 'Spaces not allowed';
    if (tag.length > 30) return 'Tag too long';
    return '';
  };

  const handleAddTag = async () => {
    const validationError = validateTag(newTag);
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      const tagsRef = collection(db, `documents/${currentUserId.slice(0, 16)}/tags`);
      const docRef = await addDoc(tagsRef, { label: newTag });
      const newTagObj = { id: docRef.id, label: newTag };
      onTagsUpdate([...availableTags, newTagObj]);
      setNewTag('');
      setError('');
    } catch (err) {
      setError('Failed to add tag');
    }
  };

  const handleDeleteTag = async (tagId) => {
    try {
      const tagRef = doc(db, `documents/${currentUserId.slice(0, 16)}/tags`, tagId);
      await deleteDoc(tagRef);
      onTagsUpdate(availableTags.filter(tag => tag.id !== tagId));
    } catch (err) {
      setError('Failed to delete tag');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && newTag) {
      handleAddTag();
    }
  };

  const sortedTags = useMemo(() => 
    [...availableTags].sort((a, b) => a.label.localeCompare(b.label)),
    [availableTags]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Manage Tags
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Tag Rules:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            - Start with # (eg: #Important)
            - Use CamelCase for multiple words (eg: #ProjectAlpha)
            - Letters and numbers only
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
          <TextField
            fullWidth
            size="small"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="#NewTag"
            error={!!error}
            helperText={error}
          />
          <Button
            variant="contained"
            onClick={handleAddTag}
            startIcon={<AddIcon />}
            disabled={!newTag}
          >
            Add
          </Button>
        </Box>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {sortedTags.map(tag => (
            <Chip
              key={tag.id}
              label={tag.label}
              onDelete={() => handleDeleteTag(tag.id)}
              color="primary"
              variant="outlined"
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagManager;