import React from 'react';
import { Box, TextField, IconButton, Button, Chip } from '@mui/material';
import { 
  Sort as SortIcon,
  Search as SearchIcon,
  Label as LabelIcon 
} from '@mui/icons-material';

const SearchBar = ({ 
  searchQuery, 
  onSearchChange, 
  onSortClick, 
  onAddNote,
  tagResults = [],
  onTagClick 
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 3, mt: 2, px: 2 }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <TextField
          size="small"
          placeholder="Search notes and tags..."
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
          sx={{ flex: 1 }}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1, color: 'action.active' }} />
          }}
        />
        <IconButton onClick={onSortClick}>
          <SortIcon />
        </IconButton>
        <Button variant="contained" onClick={onAddNote}>Add Note</Button>
      </Box>
      
      {tagResults.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {tagResults.map(tag => (
            <Chip
              key={tag.id}
              label={tag.label}
              onClick={() => onTagClick(tag)}
              icon={<LabelIcon sx={{ fontSize: 16 }} />}
              size="small"
              sx={{ cursor: 'pointer' }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default SearchBar;