// src/Components/Notes/Sidebar.jsx
import React from 'react';
import { Box } from '@mui/material';
import SearchBar from './SearchBar';
import NoteListItem from '../../Pages/Notes/NoteListItem';

const Sidebar = ({ 
  searchQuery, 
  onSearchChange, 
  onSortClick, 
  onAddNote, 
  filteredNotes = [], // Se filteredNotes for undefined, inicialize como um array vazio
  selectedNote, 
  onNoteSelect, 
  isMobile 
}) => {
  return (
    <>
      <SearchBar
        searchQuery={searchQuery}
        onSearchChange={onSearchChange}
        onSortClick={onSortClick}
        onAddNote={onAddNote}
      />
      
      <Box sx={{ pl: 2, pr: 1 }}>
        {filteredNotes.map(note => (
          <NoteListItem
            key={note.id}
            note={note}
            isSelected={selectedNote?.id === note.id}
            onClick={() => {
              onNoteSelect(note);
            }}
          />
        ))}
      </Box>
    </>
  );
};


export default Sidebar;