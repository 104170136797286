// src/Components/Notes/MobileSidebar.jsx
import React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Sidebar from './Sidebar';

const MobileSidebar = ({ 
  open, 
  onClose, 
  sidebarProps 
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 2 }}>Notes List</Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ height: '100%', overflow: 'auto' }}>
        <Sidebar {...sidebarProps} />
      </Box>
    </Dialog>
  );
};

export default MobileSidebar;