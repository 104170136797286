import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Card,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  InputLabel,
  Drawer,
  Chip,
  Divider,
  Button,
  TextField,
} from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { db } from '../../Config/firebaseConfig';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../../Config/AuthContext';
import documentTypes from './DocumentConfig.json';
import { useTheme } from '@mui/material/styles';

const DocumentWidget = ({ initialDocumentType, widgetId, boxId }) => {
  const { currentUser } = useAuth();
  const [documents, setDocuments] = useState([]);
  const [documentType, setDocumentType] = useState(initialDocumentType || 'task');
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [boxSettings, setBoxSettings] = useState({});
  const [widgetSettings, setWidgetSettings] = useState({});

  const theme = useTheme(); // Usar o tema atual
  const light = theme.palette.mode === 'light'; // Verificar se o tema é light

  const fetchDocuments = useCallback(() => {
    setLoading(true);
    const userDocsPath = `documents/${currentUser.uid.slice(0, 16)}/docs`;
    let q = query(collection(db, userDocsPath), where('documentType', '==', documentType));

    Object.keys(filters).forEach((field) => {
      if (filters[field]) {
        q = query(q, where(field, '==', filters[field]));
      }
    });

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setDocuments(docs);
      setLoading(false);
    }, (error) => {
      console.error("Erro ao buscar documentos: ", error);
      setLoading(false);
    });

    return unsubscribe;
  }, [currentUser.uid, documentType, filters]);

  useEffect(() => {
    loadSettings();
  }, []);

  useEffect(() => {
    const unsubscribe = fetchDocuments();
    return () => unsubscribe();
  }, [fetchDocuments]);

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDocumentTypeChange = (value) => {
    setDocumentType(value);
    // Limpar filtros ao mudar o tipo de documento
    setFilters({});
  };

  const handleSettingsOpen = () => setSettingsOpen(true);
  const handleSettingsClose = () => setSettingsOpen(false);

  const handleBoxSettingChange = (setting, value) => {
    setBoxSettings(prev => ({ ...prev, [setting]: value }));
  };

  const handleWidgetSettingChange = (setting, value) => {
    setWidgetSettings(prev => ({ ...prev, [setting]: value }));
  };

  const saveSettings = () => {
    const settings = {
      box: boxSettings,
      widget: {
        ...widgetSettings,
        documentType,
        filters,
      },
    };
    localStorage.setItem(`settings-box-${boxId}-widget-${widgetId}`, JSON.stringify(settings));
  };

  const loadSettings = () => {
    const savedSettings = localStorage.getItem(`settings-box-${boxId}-widget-${widgetId}`);
    if (savedSettings) {
      const { box, widget } = JSON.parse(savedSettings);
      setBoxSettings(box);
      setWidgetSettings(widget);
      setDocumentType(widget.documentType || initialDocumentType);
      setFilters(widget.filters || {});
    }
  };

  const renderDynamicFields = () => {
    const docTypeConfig = documentTypes.documentTypes.find((dt) => dt.docType === documentType);
    if (!docTypeConfig || !docTypeConfig.fields) return null;

    return Object.keys(docTypeConfig.fields).map((field) => {
      const fieldConfig = docTypeConfig.fields[field];

      if (fieldConfig.input === 'select') {
        return (
          <FormControl key={field} fullWidth sx={{ mb: 1, textAlign: 'left' }} size="small">
            <InputLabel>{fieldConfig.description}</InputLabel>
            <Select
              value={filters[field] || ''}
              label={fieldConfig.description}
              size="small"
              onChange={(e) => handleFilterChange(field, e.target.value)}
            >
              <MenuItem value="">Todos</MenuItem>
              {fieldConfig.options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
      return null;
    });
  };

  const renderSettingsDrawer = () => (
    <Drawer anchor="right" open={settingsOpen} onClose={handleSettingsClose}>
      <Box sx={{ width: 300, p: 2 }}>
        <Typography variant="h6">Configurações</Typography>
        <Divider sx={{ my: 2 }} />
        
        {/* <Typography variant="subtitle1">IDs</Typography>
        <Typography>Box ID: {boxId}</Typography> */}
        
        {/* <Divider sx={{ my: 2 }} /> */}
        
        {/* <Typography variant="subtitle1">Configurações do Box</Typography>
        <TextField
          fullWidth
          label="Título do Box"
          value={boxSettings.title || ''}
          onChange={(e) => handleBoxSettingChange('title', e.target.value)}
          margin="normal"
        /> */}

        {/* Paleta de Cores */}
        

        <Typography variant="h5">Widget</Typography>
        <Typography variant='caption'>ID: {widgetId}</Typography>
        <Divider sx={{ mt: 2 }} />
        <TextField
          fullWidth
          size='small'
          label="Título do Widget"
          value={widgetSettings.title || ''}
          sx={{ mt: 2 }}
          onChange={(e) => handleWidgetSettingChange('title', e.target.value)}
          
        />
        <FormControl fullWidth size='small' sx={{ my: 2 }}>
          <InputLabel>Tipo de Documento</InputLabel>
          <Select
            value={documentType}
            label="Tipo de Documento"
            onChange={(e) => handleDocumentTypeChange(e.target.value)}
          >
            {documentTypes.documentTypes.map((docType) => (
              <MenuItem key={docType.docType} value={docType.docType}>
                {docType.docType.charAt(0).toUpperCase() + docType.docType.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {renderDynamicFields()}



        <Typography variant="subtitle1" sx={{ mt: 2 }}>Cor de fundo</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
          {Array.from({ length: 18 }).map((_, index) => {
            const hue = index * 20;
            const color = `hsla(${hue}, 80%, 80%, 1)`;
            return (
              <Box
                key={hue}
                onClick={() => handleBoxSettingChange('backgroundColor', color)}
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: color,
                  margin: 0.5,
                  borderRadius: 1,
                  cursor: 'pointer',
                  border: boxSettings.backgroundColor === color ? '2px solid black' : 'none',
                }}
              />
            );
          })}
        </Box>

        {/* <Divider sx={{ my: 2 }} /> */}

        <Button
          variant="contained"
          fullWidth
          onClick={() => { saveSettings(); handleSettingsClose(); }}
          sx={{ mt: 2 }}
        >
          Salvar Configurações
        </Button>
      </Box>
    </Drawer>
  );

  return (
    <Box
  id={`widget-${widgetId}`}
  sx={{
    mb: 2,
    p: 1,
    backgroundColor: boxSettings.backgroundColor
      ? `hsla(${parseInt(boxSettings.backgroundColor.match(/\d+/)?.[0] || 0)}, 80%, ${
          light ? '80%' : '8%'
        }, 1)`
      : 'hsla(0, 80%, 80%, 1)',
  }}
>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="h6" sx={{ ml: 0.5, userSelect: 'none' }}>
          {widgetSettings.title || `${documentType.charAt(0).toUpperCase() + documentType.slice(1)}s`}
        </Typography>
        {/* <Typography variant="h6">bbb {boxSettings.title || `Box ${boxId}`}</Typography> */}
        <IconButton onClick={handleSettingsOpen}>
          <SettingsIcon />
        </IconButton>
      </Box>


      <Divider sx={{ mb: 1 }} />

      {loading ? (
        <CircularProgress />
      ) : documents.length > 0 ? (
        <Box>
          {documents.map((doc) => (
            <Card
              key={doc.id}
              sx={{
                mb: 0.5,
                padding: 2,
                textAlign: 'left',
                backgroundColor: boxSettings.backgroundColor
                  ? `hsla(${parseInt(boxSettings.backgroundColor.match(/\d+/)[0])}, 80%, ${light ? '94%' : '10%'}, 1)`
                  : `hsla(0, 80%, ${light ? '90%' : '10%'}, 1)`,
              }}
            >
              <Typography variant="body1">{doc.content}</Typography>
              <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
                <Typography sx={{ mr: 1, mt: 0.5 }} variant="caption" color="text.secondary">
                  Criado em: {new Date(doc.createdAt.seconds * 1000).toLocaleDateString()}
                </Typography>
                {doc.priority && <Chip size="small" label={doc.priority} variant="outlined" />}
                {doc.taskStatus && <Chip size="small" label={doc.taskStatus} variant="outlined" />}
              </Box>
            </Card>
          ))}
        </Box>
      ) : (
        <Typography>Nenhum documento encontrado.</Typography>
      )}

      {renderSettingsDrawer()}
    </Box>
  );
};

export default DocumentWidget;
