import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const AnimeSearchCarousel = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedAnimes, setSelectedAnimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    setLoading(true);
    setError(null);

    const query = `
      query ($search: String, $page: Int, $perPage: Int) {
        Page (page: $page, perPage: $perPage) {
          media (search: $search, type: ANIME) {
            id
            title {
              romaji
            }
          }
        }
      }
    `;

    const variables = {
      search: searchTerm,
      page: 1,
      perPage: 10
    };

    const url = 'https://graphql.anilist.co';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        query: query,
        variables: variables
      })
    };

    try {
      const response = await fetch(url, options);
      const json = await response.json();
      if (response.ok) {
        setSearchResults(json.data.Page.media);
      } else {
        throw new Error(json.errors?.[0]?.message || 'Unknown error occurred');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (anime) => {
    setSelectedAnimes(prev => {
      const isSelected = prev.some(item => item.id === anime.id);
      if (isSelected) {
        return prev.filter(item => item.id !== anime.id);
      } else {
        return [...prev, anime];
      }
    });
  };

  const fetchAnimeDetails = async (id) => {
    const query = `
      query ($id: Int) {
        Media (id: $id, type: ANIME) {
          id
          title {
            romaji
          }
          coverImage {
            large
          }
        }
      }
    `;

    const variables = { id };

    const url = 'https://graphql.anilist.co';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        query: query,
        variables: variables
      })
    };

    try {
      const response = await fetch(url, options);
      const json = await response.json();
      if (response.ok) {
        return json.data.Media;
      } else {
        throw new Error(json.errors?.[0]?.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error fetching anime details:', error);
      return null;
    }
  };

  const loadSelectedAnimeDetails = async () => {
    const detailedAnimes = await Promise.all(
      selectedAnimes.map(anime => fetchAnimeDetails(anime.id))
    );
    setSelectedAnimes(detailedAnimes.filter(anime => anime !== null));
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
      <TextField
        fullWidth
        label="Buscar anime"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" onClick={handleSearch} sx={{ mb: 2 }}>
        Buscar
      </Button>

      {loading && <Typography>Carregando...</Typography>}
      {error && <Typography color="error">{error}</Typography>}

      {searchResults.length > 0 && (
        <List>
          {searchResults.map((anime) => (
            <ListItem key={anime.id} dense button onClick={() => handleSelect(anime)}>
              <Checkbox
                edge="start"
                checked={selectedAnimes.some(item => item.id === anime.id)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={anime.title.romaji} />
            </ListItem>
          ))}
        </List>
      )}

      {selectedAnimes.length > 0 && (
        <Button variant="contained" onClick={loadSelectedAnimeDetails} sx={{ my: 2 }}>
          Carregar detalhes e mostrar carrossel
        </Button>
      )}

      {selectedAnimes.length > 0 && selectedAnimes[0].coverImage && (
        <Carousel>
          {selectedAnimes.map((anime) => (
            <Card key={anime.id}>
              <CardMedia
                component="img"
                height="300"
                image={anime.coverImage.large}
                alt={anime.title.romaji}
              />
              <CardContent>
                <Typography variant="h6">{anime.title.romaji}</Typography>
              </CardContent>
            </Card>
          ))}
        </Carousel>
      )}
    </Box>
  );
};

export default AnimeSearchCarousel;