import React, { useState, useEffect, useRef } from 'react';
import { Box, Paper, Typography } from '@mui/material';

const TagAutocomplete = ({ availableTags, onSelectTag, position }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  
  const handleKeyDown = (e) => {
    switch(e.key) {
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => Math.max(0, prev - 1));
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => Math.min(4, prev + 1));
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <Paper 
      elevation={3}
      style={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        zIndex: 1000,
        maxWidth: 200
      }}
    >
      {availableTags.slice(0, 5).map((tag, index) => (
        <Box
          key={tag.id}
          p={1}
          bgcolor={index === selectedIndex ? 'action.selected' : 'background.paper'}
          onClick={() => onSelectTag(tag.label)}
          sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'action.hover' } }}
        >
          <Typography>{tag.label}</Typography>
        </Box>
      ))}
    </Paper>
  );
};

export default TagAutocomplete;