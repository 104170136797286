// src/Components/Dashboard/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Box as MUIBox, Button, Typography } from '@mui/material';
import Box from '../Box/Box';

const Dashboard = () => {
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    const savedBoxes = JSON.parse(localStorage.getItem('boxesConfig'));
    if (savedBoxes) {
      setBoxes(savedBoxes);
    }
  }, []);

  const addBox = () => {
    const newBox = {
      id: Date.now(),
      widgets: [],
      width: 1,
    };
    const updatedBoxes = [...boxes, newBox];
    setBoxes(updatedBoxes);
    localStorage.setItem('boxesConfig', JSON.stringify(updatedBoxes));
  };

  const removeBox = (id) => {
    const updatedBoxes = boxes.filter(box => box.id !== id);
    setBoxes(updatedBoxes);
    localStorage.setItem('boxesConfig', JSON.stringify(updatedBoxes));
  };

  // Função para atualizar uma Box
  const updateBox = (updatedBox) => {
    const updatedBoxes = boxes.map(box => box.id === updatedBox.id ? updatedBox : box);
    setBoxes(updatedBoxes);
    localStorage.setItem('boxesConfig', JSON.stringify(updatedBoxes));
  };

  return (
    <MUIBox maxWidth={"false"} sx={{ display: 'flex', flexDirection: 'column' }}>
      <MUIBox maxWidth={"false"} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: 1, paddingTop: 2 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Button variant="contained" size='medium' onClick={addBox}>
        Add Box
      </Button>
      </MUIBox>
      <MUIBox sx={{ display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box' }}>
        {boxes.map((box) => (
          <Box key={box.id} box={box} onRemove={removeBox} onUpdateBox={updateBox} />
        ))}
      </MUIBox>
    </MUIBox>
  );
};

export default Dashboard;
