import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    IconButton,
    TextField,
    Typography,
    AppBar,
    Toolbar,
    Drawer,
    List,
    ListItem, ListItemIcon,ListItemText,
    ListItemButton,
    Avatar,
    InputAdornment,
    Paper,
    // MenuIcon
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Article';
import TaskIcon from '@mui/icons-material/Task';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


import { useAuthState } from 'react-firebase-hooks/auth';
import { useAuth } from '../../Config/AuthContext'; // Assume this exists

const DRAWER_WIDTH = 240;

const DocumentEditor = () => {
    const [user] = useAuthState(useAuth());
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    // Styling constants
    const darkTheme = {
        background: '#1e1e1e',
        paper: '#252525',
        text: '#ffffff',
        secondaryText: '#a0a0a0',
        border: '#404040'
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const renderDrawerContent = () => (
        <>
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    Documents
                </Typography>
            </Toolbar>
            <Box sx={{ px: 2, py: 1 }}>
                <TextField
                    fullWidth
                    size="small"
                    placeholder="Search in notes"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: darkTheme.secondaryText }} />
                            </InputAdornment>
                        ),
                        sx: {
                            color: darkTheme.text,
                            backgroundColor: darkTheme.paper,
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: darkTheme.border
                            }
                        }
                    }}
                />
            </Box>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ mx: 2, my: 1 }}
            >
                Add note
            </Button>
            <List>
                <ListItem disablePadding>
                    <ListItemButton selected={true}>
                        <ListItemIcon>
                            <DescriptionIcon sx={{ color: darkTheme.text }} />
                        </ListItemIcon>
                        <ListItemText primary="Notes" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <TaskIcon sx={{ color: darkTheme.text }} />
                        </ListItemIcon>
                        <ListItemText primary="Tasks" />
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    );

    const renderMainContent = () => (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            height: '100vh',
            backgroundColor: darkTheme.background,
            color: darkTheme.text
        }}>
            <Box sx={{ p: 3, flex: 1 }}>
                {selectedDoc ? (
                    <>
                        <TextField
                            fullWidth
                            variant="standard"
                            placeholder="Title"
                            sx={{
                                mb: 3,
                                '& input': {
                                    color: darkTheme.text,
                                    fontSize: '24px',
                                    fontWeight: 'bold'
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: 'transparent'
                                }
                            }}
                        />
                        <Box 
                            component="img" 
                            src="/placeholder-image.jpg"
                            sx={{
                                width: '100%',
                                maxHeight: 300,
                                objectFit: 'cover',
                                borderRadius: 1,
                                mb: 2
                            }}
                        />
                        <TextField
                            fullWidth
                            multiline
                            variant="standard"
                            placeholder="Start writing..."
                            minRows={4}
                            sx={{
                                '& textarea': {
                                    color: darkTheme.text
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: 'transparent'
                                }
                            }}
                        />
                    </>
                ) : (
                    <Typography variant="body1" color={darkTheme.secondaryText} align="center">
                        Here will be displayed the full content.
                    </Typography>
                )}
            </Box>
            <Box sx={{ 
                p: 2, 
                borderTop: `1px solid ${darkTheme.border}`,
                display: 'flex',
                alignItems: 'center',
                gap: 1
            }}>
                <AccessTimeIcon sx={{ color: darkTheme.secondaryText }} />
                <Typography variant="body2" color={darkTheme.secondaryText}>
                    CTRL + S to save, or Autosave is set to 10 seconds
                </Typography>
                <Button 
                    variant="text" 
                    size="small"
                    sx={{ color: '#2196f3', ml: 'auto' }}
                >
                    Change this setting
                </Button>
            </Box>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: darkTheme.paper,
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={toggleDrawer}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <Avatar
                        src={user?.photoURL}
                        alt={user?.displayName}
                    />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={drawerOpen}
                sx={{
                    width: drawerOpen ? DRAWER_WIDTH : 0,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: DRAWER_WIDTH,
                        boxSizing: 'border-box',
                        backgroundColor: darkTheme.paper,
                        color: darkTheme.text,
                        borderRight: `1px solid ${darkTheme.border}`
                    },
                }}
            >
                <Toolbar /> {/* Spacer for AppBar */}
                {renderDrawerContent()}
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 0,
                    width: `calc(100% - ${drawerOpen ? DRAWER_WIDTH : 0}px)`,
                }}
            >
                <Toolbar /> {/* Spacer for AppBar */}
                {renderMainContent()}
            </Box>
        </Box>
    );
};

export default DocumentEditor;