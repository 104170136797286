import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert
} from '@mui/material';
import {
  ExpandMore,
  FileUpload,
  Visibility,
  Warning,
  Add,
  Close,
} from '@mui/icons-material';

const CVMForm = () => {
  const [formData, setFormData] = useState({
    dsInform: {
      anoBase: new Date().getFullYear().toString(),
      dtGeracArq: new Date().toLocaleDateString('pt-BR'),
      motivoEntreg: '1'
    },
    dsAudit: {
      tpPfPj: 'PJ',
      nrPfPj: '',
      cdCvm: '',
      dsNome: '',
      dsEmail: ''
    },
    listaEnder: [],
    listaResp: [],
    listaEntidAudita: [],
    qtOutraEntidAudita: '0',
    faturaAnual: {
      vlAudit: '',
      prAudit: '',
      hhAudit: ''
    },
    dsCriterHonor: '',
    rh: {
      qtSocios: '',
      qtFuncTecno: ''
    },
    listaEntidColig: []
  });

  const [csvPreviews, setCsvPreviews] = useState({
    enderecos: [],
    responsaveis: [],
    entidadesAuditadas: [],
    entidadesColigadas: []
  });

  const [showPreview, setShowPreview] = useState(false);
  const [xmlOutput, setXmlOutput] = useState('');

  const handleCSVUpload = (type) => async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const text = await file.text();
    const lines = text.split('\n');
    const headers = lines[0].split(',');
    const data = lines.slice(1).map(line => {
      const values = line.split(',');
      return headers.reduce((obj, header, index) => {
        obj[header.trim()] = values[index]?.trim() || '';
        return obj;
      }, {});
    });

    setCsvPreviews(prev => ({
      ...prev,
      [type]: data
    }));

    setFormData(prev => ({
      ...prev,
      [`lista${type.charAt(0).toUpperCase() + type.slice(1)}`]: data
    }));
  };

  const generateXML = () => {
    const currentDate = new Date().toLocaleDateString('pt-BR');
    let xml = `<?xml version="1.0" encoding="windows-1252"?>
<DOC_ARQ xmlns="urn:audtanual">
  <COD_DOC>23</COD_DOC>
  <INFORM_AUDIT>
    <DS_INFORM>
      <ANO_BASE>${formData.dsInform.anoBase}</ANO_BASE>
      <DT_GERAC_ARQ>${currentDate}</DT_GERAC_ARQ>
      <MOTIVO_ENTREG>${formData.dsInform.motivoEntreg}</MOTIVO_ENTREG>
    </DS_INFORM>
    <DS_AUDIT>
      <TP_PF_PJ>${formData.dsAudit.tpPfPj}</TP_PF_PJ>
      <NR_PF_PJ>${formData.dsAudit.nrPfPj}</NR_PF_PJ>
      <CD_CVM>${formData.dsAudit.cdCvm}</CD_CVM>
      <DS_NOME>${formData.dsAudit.dsNome}</DS_NOME>
      <DS_EMAIL>${formData.dsAudit.dsEmail}</DS_EMAIL>
    </DS_AUDIT>`;

    if (csvPreviews.enderecos.length > 0) {
      xml += '\n    <LISTA_ENDER>';
      csvPreviews.enderecos.forEach(endereco => {
        xml += `
      <ENDER>
        <TP_ENDER>${endereco.tpEnder}</TP_ENDER>
        <NM_FILIAL>${endereco.nmFilial}</NM_FILIAL>
        <NM_LOGRAD>${endereco.nmLograd}</NM_LOGRAD>
        <DS_COMPL>${endereco.dsCompl}</DS_COMPL>
        <DS_BAIRRO>${endereco.dsBairro}</DS_BAIRRO>
        <CD_MUN>${endereco.cdMun}</CD_MUN>
        <NR_CEP>${endereco.nrCep}</NR_CEP>
        <NR_DDD_TEL>${endereco.nrDddTel}</NR_DDD_TEL>
        <NR_TEL>${endereco.nrTel}</NR_TEL>
        <NR_DDD_FAX>${endereco.nrDddFax}</NR_DDD_FAX>
        <NR_FAX>${endereco.nrFax}</NR_FAX>
      </ENDER>`;
      });
      xml += '\n    </LISTA_ENDER>';
    }

    // Adiciona as seções de listas
    if (csvPreviews.entidadesAuditadas.length > 0) {
        xml += '\n    <LISTA_ENTID_AUDITA>';
        csvPreviews.entidadesAuditadas.forEach(entidade => {
          xml += `
        <ENTID_AUDITA>
          <TP_ENTID>${entidade.tpEntid}</TP_ENTID>
          <NR_PJ>${entidade.nrPj}</NR_PJ>
          <DS_NOME>${entidade.dsNome}</DS_NOME>
          <DT_CONTR>${entidade.dtContr}</DT_CONTR>
          <PR_FATURA>${entidade.prFatura}</PR_FATURA>
        </ENTID_AUDITA>`;
        });
        xml += '\n    </LISTA_ENTID_AUDITA>';
      }
  
      if (csvPreviews.entidadesColigadas.length > 0) {
        xml += '\n    <LISTA_ENTID_COLIG>';
        csvPreviews.entidadesColigadas.forEach(entidade => {
          xml += `
        <ENTID_COLIG>
          <ST_ESTR>${entidade.stEstr}</ST_ESTR>
          <NR_PJ>${entidade.nrPj}</NR_PJ>
          <DS_NOME>${entidade.dsNome}</DS_NOME>
          <DS_SETOR_ATIVD>${entidade.dsSetorAtivd}</DS_SETOR_ATIVD>
        </ENTID_COLIG>`;
        });
        xml += '\n    </LISTA_ENTID_COLIG>';
      }

    xml += `
  </INFORM_AUDIT>
</DOC_ARQ>`;

    setXmlOutput(xml);
    setShowPreview(true);
  };

  return (
    <Container sx={{ my: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>Formulário CVM - Informações Anuais</Typography>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Informações Básicas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Ano Base"
                  value={formData.dsInform.anoBase}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    dsInform: { ...prev.dsInform, anoBase: e.target.value }
                  }))}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Motivo da Entrega</InputLabel>
                  <Select
                    value={formData.dsInform.motivoEntreg}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      dsInform: { ...prev.dsInform, motivoEntreg: e.target.value }
                    }))}
                    label="Motivo da Entrega"
                  >
                    <MenuItem value="1">Apresentação</MenuItem>
                    <MenuItem value="2">Reapresentação Espontânea</MenuItem>
                    <MenuItem value="3">Reapresentação por Exigência</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de Pessoa</InputLabel>
                  <Select
                    value={formData.dsAudit.tpPfPj}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      dsAudit: { ...prev.dsAudit, tpPfPj: e.target.value }
                    }))}
                    label="Tipo de Pessoa"
                  >
                    <MenuItem value="PJ">Pessoa Jurídica</MenuItem>
                    <MenuItem value="PF">Pessoa Física</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Importação de Dados (CSV)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>Endereços</Typography>
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<FileUpload />}
                    sx={{ mb: 1 }}
                  >
                    Importar CSV de Endereços
                    <input
                      type="file"
                      hidden
                      accept=".csv"
                      onChange={handleCSVUpload('enderecos')}
                    />
                  </Button>
                  {csvPreviews.enderecos.length > 0 && (
                    <Alert severity="success" sx={{ mt: 1 }}>
                      {csvPreviews.enderecos.length} endereços importados
                    </Alert>
                  )}
                </Paper>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>Responsáveis</Typography>
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<FileUpload />}
                    sx={{ mb: 1 }}
                  >
                    Importar CSV de Responsáveis
                    <input
                      type="file"
                      hidden
                      accept=".csv"
                      onChange={handleCSVUpload('responsaveis')}
                    />
                  </Button>
                  {csvPreviews.responsaveis.length > 0 && (
                    <Alert severity="success" sx={{ mt: 1 }}>
                      {csvPreviews.responsaveis.length} responsáveis importados
                    </Alert>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>Entidades Auditadas</Typography>
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<FileUpload />}
                    sx={{ mb: 1 }}
                  >
                    Importar CSV de Entidades Auditadas
                    <input
                      type="file"
                      hidden
                      accept=".csv"
                      onChange={handleCSVUpload('entidadesAuditadas')}
                    />
                  </Button>
                  {csvPreviews.entidadesAuditadas.length > 0 && (
                    <Alert severity="success" sx={{ mt: 1 }}>
                      {csvPreviews.entidadesAuditadas.length} entidades auditadas importadas
                    </Alert>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>Entidades Coligadas</Typography>
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<FileUpload />}
                    sx={{ mb: 1 }}
                  >
                    Importar CSV de Entidades Coligadas
                    <input
                      type="file"
                      hidden
                      accept=".csv"
                      onChange={handleCSVUpload('entidadesColigadas')}
                    />
                  </Button>
                  {csvPreviews.entidadesColigadas.length > 0 && (
                    <Alert severity="success" sx={{ mt: 1 }}>
                      {csvPreviews.entidadesColigadas.length} entidades coligadas importadas
                    </Alert>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={generateXML}
            startIcon={<Visibility />}
          >
            Gerar XML
          </Button>
        </Box>

        <Dialog 
          open={showPreview} 
          onClose={() => setShowPreview(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Preview do XML
            <IconButton onClick={() => setShowPreview(false)}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <pre style={{ 
              backgroundColor: '#f5f5f5', 
              padding: '16px', 
              borderRadius: '4px',
              overflowX: 'auto' 
            }}>
              {xmlOutput}
            </pre>
          </DialogContent>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default CVMForm;