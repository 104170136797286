import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../Config/firebaseConfig'; // Certifique-se de configurar o Firebase corretamente
import Dashboard from './../../Components/Dashboard/Dashboard';
import PromotionalContent from './../../Components/PromotionalContent/PromotionalContent';
import { Container } from '@mui/material';

const Home = () => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Container maxWidth="false">
      {user ? <Dashboard /> : <PromotionalContent />}
    </Container>
  );
};

export default Home;
