import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PhotoIcon from '@mui/icons-material/Photo';
import NoteIcon from '@mui/icons-material/Note';
import MapIcon from '@mui/icons-material/Map';
import Home from './../Pages/Home/Home';
import Profile from './../Pages/Profile/Profile';
import Notes from './../Pages/Notes/PageNotes';
import ToDo from './../Pages/ToDo/ToDoPage';
import Store from '../Components/Store/Store';
import StoreManager from '../Components/Store/StoreManagerPage';
import ImageMetadataDropPage from '../Components/ImageMetadataDrop/ImageMetadataDrop';
import { Navigate } from 'react-router-dom';
import DocumentPage from '../Pages/Document/DocumentPage';
import ArticleIcon from '@mui/icons-material/Article';
import MarkdownMindmap from '../Components/MindMap/MindMap';
import CVMFormComponent from '../Components/CVMFormComponent/CVMFormComponent';


const routes = [
  {
    parent: 'Documents',
    path: '/',
    component: Home,
    navLabel: 'Desks',
    pageTitle: 'aDesk (alpha)',
    icon: <AssessmentIcon />,
    showInNav: true,
  },
  {
    parent: false,
    path: '/profile',
    component: Profile,
    navLabel: 'Profile',
    pageTitle: 'Profile',
    icon: <AccountCircleIcon />,
    showInNav: false,
  },
  {
    parent: false,
    path: '/to/:nickname',
    component: Profile,
    navLabel: 'Profile',
    pageTitle: 'Profile',
    showInNav: false, // Hidden in navigation
  },
  {
    parent: 'Beta',
    path: '/cvm',
    component: CVMFormComponent,
    navLabel: 'CVM Form',
    pageTitle: 'CVM Form',
    icon: <NoteIcon />,
    showInNav: false, // Shown in navigation
  },
  {
    parent: 'Documents',
    path: '/notes',
    component: Notes,
    navLabel: 'Notes',
    pageTitle: 'Notes',
    icon: <NoteIcon />,
    showInNav: true, // Shown in navigation
  },
  {
    parent: 'Documents',
    path: '/notes/:query?',
    component: Notes,
    navLabel: 'Notes',
    pageTitle: 'Notes',
    icon: <NoteIcon />,
    showInNav: false,
  },
  {
    parent: false,
    path: '/documents',
    component: DocumentPage,
    navLabel: 'Documents',
    pageTitle: 'Documents',
    icon: <ArticleIcon />,
    showInNav: false, // Shown in navigation
  },
  // {
  // parent: false,
  // path: '/tasks',
  //   component: ToDo,
  //   navLabel: 'Tasks',
  //   pageTitle: 'Tasks',
  //   icon: <AssignmentTurnedInIcon />,
  //   showInNav: false, // Shown in navigation
  // },
  {
    parent: 'Beta',
    path: '/tools/image-metadata',
    component: ImageMetadataDropPage,
    navLabel: 'Image Metadata viewer',
    pageTitle: 'Image Metadata',
    icon: <PhotoIcon />,
    showInNav: true, // Shown in navigation
  },
  {
    parent: false,
    path: '/shop',
    component: StoreManager,
    navLabel: 'Shop',
    pageTitle: 'My shop (alpha)',
    icon: <LocalOfferIcon />,
    showInNav: false,
  },
  {
    parent: 'Beta',
    path: '/shop/sale',
    component: StoreManager,
    navLabel: 'My sales',
    pageTitle: 'My sales (alpha)',
    icon: <LocalOfferIcon />,
    showInNav: true,
  },
  // {
  //   parent: 'MindMap',
  //   path: '/mindmap',
  //   component: MarkdownMindmap,
  //   navLabel: 'My mindmaps',
  //   pageTitle: 'My mindmaps (alpha)',
  //   icon: <MapIcon />,
  //   showInNav: true,
  // },
  {
    parent: 'Shop',
    path: '/shop/:adeskUserID/ads/:productId',
    component: Store, // Now you should pass just the function or class
    navLabel: 'Offer Details',
    pageTitle: 'Offer Details',
    icon: <LocalOfferIcon />,
    showInNav: false,
  }
];

export default routes;

