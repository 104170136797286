import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../Config/firebaseConfig';
import { Container, Typography, Box, Card, CardContent, CardMedia } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Helmet } from 'react-helmet';

const NotesDisplay = () => {
  const { adeskUserID, productId } = useParams();
  const [ad, setAd] = useState(null);

  useEffect(() => {
    const fetchAd = async () => {
      try {
        const adDocRef = doc(db, `store/${adeskUserID}/ads/${productId}`);
        const adDoc = await getDoc(adDocRef);

        if (adDoc.exists()) {
          setAd(adDoc.data());
        } else {
          console.log('No such ad!');
        }
      } catch (error) {
        console.error('Error fetching ad:', error);
      }
    };

    fetchAd();
  }, [adeskUserID, productId]);

  if (!ad) {
    return <Typography>Loading ad details...</Typography>;
  }

  return (
    <Container maxWidth="false">
      <Helmet>
        <title>{ad.title}</title>
        <meta property="og:title" content={ad.title} />
        <meta property="og:description" content={ad.description} />
        <meta property="og:image" content={ad.imageUrls && ad.imageUrls.length > 0 ? ad.imageUrls[0] : 'fallback-image-url.jpg'} />
        <meta property="og:url" content={`${window.location.origin}/store/${adeskUserID}/ads/${productId}`} />
      </Helmet>
      
      <Box sx={{ marginTop: 4 }}>
        {ad.imageUrls && ad.imageUrls.length > 0 && (
          <Swiper pagination={{ dynamicBullets: true }} modules={[Pagination, Navigation]} className="mySwiper" navigation={true}>
            {ad.imageUrls.map((url, index) => (
              <SwiperSlide key={index}>
                <img src={url} alt={`ad-image-${index}`} loading="lazy" />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        
        <Card>
          <CardContent>
            <Typography variant="h5">{ad.title}</Typography>
            <Typography variant="body1">{ad.description}</Typography>
            <Typography variant="h6">R$ {ad.price}</Typography>
            <Typography variant="body2" color="textSecondary">
              {new Date(ad.createdAt.seconds * 1000).toLocaleDateString()}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};


export async function getServerSideProps(context) {
  const { adeskUserID, productId } = context.params;

  try {
    const adDocRef = doc(db, `store/${adeskUserID}/ads/${productId}`);
    const adDoc = await getDoc(adDocRef);

    if (adDoc.exists()) {
      return {
        props: {
          ad: adDoc.data(),
          adeskUserID,
          productId,
        },
      };
    } else {
      return {
        props: {
          ad: null,
        },
      };
    }
  } catch (error) {
    console.error('Error fetching ad:', error);
    return {
      props: {
        ad: null,
      },
    };
  }
}


export default NotesDisplay;
