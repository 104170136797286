// src/Components/Notes/SortPopover.jsx
import React from 'react';
import { Popover, List, ListItem, ListItemText } from '@mui/material';

const SortPopover = ({ anchorEl, onClose, onSortChange }) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>
        <ListItem button onClick={() => onSortChange('newest')} sx={{ px: 2, cursor: 'pointer' }}>
          <ListItemText primary="Newest first" />
        </ListItem>
        <ListItem button onClick={() => onSortChange('oldest')} sx={{ px: 2, cursor: 'pointer' }}>
          <ListItemText primary="Oldest first" />
        </ListItem>
        <ListItem button onClick={() => onSortChange('updated')} sx={{ px: 2, cursor: 'pointer' }}>
          <ListItemText primary="Last updated" />
        </ListItem>
      </List>
    </Popover>
  );
};

export default SortPopover;