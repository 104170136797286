import React, { useState } from 'react';
import {
  Box as MUIBox,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Divider,
} from '@mui/material';
import { Settings as SettingsIcon, Delete as DeleteIcon } from '@mui/icons-material';
import DocumentWidget from '../Document/DocumentWidget';
import ApiConnectWidget from '../ApiConnect/ApiConnectWidget';

const Box = ({ box, onRemove, onUpdateBox }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [boxConfig, setBoxConfig] = useState({
    title: box.title || `Box ${box.id}`,
    width: box.width,
    saveToServer: box.saveToServer,
    allowedWidgetTypes: box.allowedWidgetTypes || ['Document', 'ApiConnect']
  });
  const [widgetsConfig, setWidgetsConfig] = useState(box.widgets || []);
  const [selectedWidgetConfig, setSelectedWidgetConfig] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedWidgetConfig(null);
    setShowDeleteConfirm(false);
  };

  const handleConfirm = () => {
    const updatedBox = {
      ...box,
      ...boxConfig,
      widgets: widgetsConfig,
    };
    onUpdateBox(updatedBox);
    handleCloseDialog();
  };

  const handleDeleteBox = () => {
    if (showDeleteConfirm) {
      onRemove(box.id);
      handleCloseDialog();
    } else {
      setShowDeleteConfirm(true);
    }
  };

  const addWidget = () => {
    const newWidget = {
      id: Date.now(),
      type: boxConfig.allowedWidgetTypes[0],
      title: `Widget ${widgetsConfig.length + 1}`,
      config: {}
    };
    setWidgetsConfig([...widgetsConfig, newWidget]);
  };

  const removeWidget = (widgetId) => {
    const updatedWidgets = widgetsConfig.filter(widget => widget.id !== widgetId);
    setWidgetsConfig(updatedWidgets);
  };

  const handleWidgetSettingsOpen = (widget) => {
    setSelectedWidgetConfig(widget);
  };

  const updateWidgetConfig = (updatedWidget) => {
    const updatedWidgets = widgetsConfig.map(widget => 
      widget.id === updatedWidget.id ? updatedWidget : widget
    );
    setWidgetsConfig(updatedWidgets);
    setSelectedWidgetConfig(null);
  };

  const renderWidgetSettings = () => {
    if (!selectedWidgetConfig) return null;

    return (
      <>
        <Typography variant="h6" sx={{ mt: 2 }}>Widget Settings</Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Widget Title"
          value={selectedWidgetConfig.title || ''}
          onChange={(e) => {
            const updatedWidget = {
              ...selectedWidgetConfig,
              title: e.target.value
            };
            updateWidgetConfig(updatedWidget);
          }}
        />
        <FormControl fullWidth margin="normal">
          <Select
            value={selectedWidgetConfig.type}
            onChange={(e) => {
              const updatedWidget = {
                ...selectedWidgetConfig,
                type: e.target.value
              };
              updateWidgetConfig(updatedWidget);
            }}
          >
            {boxConfig.allowedWidgetTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  return (
    <MUIBox
      sx={{
        padding: 1,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        width: `${(box.width / 12) * 100}%`,
      }}
    >
      <MUIBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">{boxConfig.title}</Typography>
        <IconButton onClick={handleOpenDialog}>
          <SettingsIcon />
        </IconButton>
      </MUIBox>

      <MUIBox sx={{ marginTop: 2 }}>
        {widgetsConfig.map((widget) => (
          <MUIBox key={widget.id} sx={{ mb: 2 }}>

            {widget.type === 'Document' && (
              <DocumentWidget 
                widgetId={widget.id} 
                boxId={box.id}
                initialDocumentType={widget.config?.documentType}
              />
            )}
            {widget.type === 'ApiConnect' && (
              <ApiConnectWidget 
                widgetId={widget.id}
                boxId={box.id}
              />
            )}
          </MUIBox>
        ))}
      </MUIBox>

      <Dialog 
        open={dialogOpen} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Box Configuration</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Box Settings</Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Box Title"
            value={boxConfig.title}
            onChange={(e) => setBoxConfig({ ...boxConfig, title: e.target.value })}
          />
          <FormControl fullWidth margin="normal">
            <Typography>Box Width</Typography>
            <Select
              value={boxConfig.width}
              onChange={(e) => setBoxConfig({ ...boxConfig, width: e.target.value })}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((col) => (
                <MenuItem key={col} value={col}>
                  {col} Column{col > 1 ? 's' : ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Typography>Allowed Widget Types</Typography>
            <Select
              multiple
              value={boxConfig.allowedWidgetTypes}
              onChange={(e) => setBoxConfig({ ...boxConfig, allowedWidgetTypes: e.target.value })}
              renderValue={(selected) => selected.join(', ')}
            >
              <MenuItem value="Document">Document Widget</MenuItem>
              <MenuItem value="ApiConnect">ApiConnect Widget</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={boxConfig.saveToServer}
                onChange={(e) => setBoxConfig({ ...boxConfig, saveToServer: e.target.checked })}
              />
            }
            label="Save to Server"
          />

          <Divider sx={{ my: 2 }} />
          
          <Typography variant="h6">Widgets</Typography>
          <List>
            {widgetsConfig.map((widget) => (
              <ListItem key={widget.id}>
                <ListItemText 
                  primary={widget.title}
                  secondary={`Type: ${widget.type}`}
                />
                <ListItemSecondaryAction>
                  <IconButton 
                    edge="end" 
                    onClick={() => handleWidgetSettingsOpen(widget)}
                    sx={{ mr: 1 }}
                  >
                    <SettingsIcon />
                  </IconButton>
                  <IconButton 
                    edge="end" 
                    onClick={() => removeWidget(widget.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          
          <Button 
            variant="outlined" 
            onClick={addWidget}
            sx={{ mt: 2 }}
            disabled={boxConfig.allowedWidgetTypes.length === 0}
          >
            Add Widget
          </Button>

          {renderWidgetSettings()}

          <Divider sx={{ my: 2 }} />
          
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={handleDeleteBox}
            startIcon={<DeleteIcon />}
            sx={{ mt: 2 }}
          >
            {showDeleteConfirm ? 'Click again to confirm deletion' : 'Delete Box'}
          </Button>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button 
            onClick={handleConfirm} 
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </MUIBox>
  );
};

export default Box;