// src/Pages/Notes/NotesPage.jsx
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAuth } from '../../Config/AuthContext';
import { db } from '../../Config/firebaseConfig';
import { collection, addDoc, updateDoc, getDocs, doc, deleteDoc } from 'firebase/firestore';
import {
  Container,
  Typography,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  useMediaQuery
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import DOMPurify from 'dompurify';
import NoteViewer from './NoteViewer';
import Sidebar from '../../Components/Notes/Sidebar';
import MobileSidebar from '../../Components/Notes/MobileSidebar';
import SortPopover from '../../Components/Notes/SortPopover';
import { useParams, useNavigate } from 'react-router-dom';
import TagManager from '../../Components/TagManager/TagManager';


const MIN_SIDEBAR_WIDTH = 320;
const MAX_SIDEBAR_WIDTH = 512;
const STORAGE_KEY = 'notesSidebarWidth';

const NotesPage = () => {
  const { currentUser } = useAuth();
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertAction, setAlertAction] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [spellCheckEnabled, setSpellCheckEnabled] = useState(false);
  const [tempNote, setTempNote] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState('newest');
  const [isDragging, setIsDragging] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentContent, setCurrentContent] = useState('');
  const [lastSavedContent, setLastSavedContent] = useState('');
  const [savingIntervalId, setSavingIntervalId] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [tagManagerOpen, setTagManagerOpen] = useState(false);
  const [availableTags, setAvailableTags] = useState([]);
  const [tagResults, setTagResults] = useState([]);
  const { query } = useParams();
  const navigate = useNavigate();
  const [skipUrlUpdate, setSkipUrlUpdate] = useState(false);

  const [sidebarWidth, setSidebarWidth] = useState(() => {
    const savedWidth = localStorage.getItem(STORAGE_KEY);
    return savedWidth ? parseInt(savedWidth, 10) : MIN_SIDEBAR_WIDTH;
  });

  const tagCreationTimeoutRef = useRef(null);

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (tagCreationTimeoutRef.current) {
        clearTimeout(tagCreationTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    const loadData = async () => {
      // Load tags
      const tagsRef = collection(db, `documents/${currentUser.uid.slice(0, 16)}/tags`);
      const tagsSnapshot = await getDocs(tagsRef);
      const loadedTags = tagsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAvailableTags(loadedTags);

      // Load notes
      await loadNotes();
    };

    loadData();
  }, [currentUser]);

  useEffect(() => {
    if (!query || skipUrlUpdate || !notes.length) return;

    const decodedQuery = decodeURIComponent(query);
    
    if (decodedQuery.startsWith('note=')) {
      const noteId = decodedQuery.slice(5);
      const note = notes.find(n => n.id === noteId);
      if (note) {
        setSelectedNote(note);
        setSearchQuery('');
      }
    } else {
      setSearchQuery(decodedQuery);
    }
  }, [query, notes, skipUrlUpdate]);

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);

    const startX = e.clientX;
    const startWidth = sidebarWidth;

    function handleMouseMove(e) {
      const newWidth = Math.min(
        Math.max(startWidth + (e.clientX - startX), MIN_SIDEBAR_WIDTH),
        MAX_SIDEBAR_WIDTH
      );
      setSidebarWidth(newWidth);
      localStorage.setItem(STORAGE_KEY, newWidth.toString());
    }

    function handleMouseUp() {
      setIsDragging(false);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [sidebarWidth]);

  const loadNotes = async () => {
    try {
      const docRef = collection(db, `documents/${currentUser.uid.slice(0, 16)}/docs`);
      const querySnapshot = await getDocs(docRef);
      const loadedDocs = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter(doc => doc.documentType === 'note')
        .sort((a, b) => {
          const dateA = b.createdAt instanceof Date ? b.createdAt : b.createdAt.toDate();
          const dateB = a.createdAt instanceof Date ? a.createdAt : a.createdAt.toDate();
          return dateA - dateB;
        });
      setNotes(loadedDocs);
      if (loadedDocs.length > 0) {
        setSelectedNote(loadedDocs[0]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error loading notes:', error);
      setSnackbarMessage('Error loading notes.');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleAddNote = () => {
    const newTempNote = {
      id: 'temp-' + Date.now(),
      content: '',
      creatorId: currentUser.uid,
      creatorName: currentUser.displayName || 'Anonymous',
      createdAt: new Date(),
      documentType: 'note',
      isTemp: true
    };

    setTempNote(newTempNote);
    setNotes([newTempNote, ...notes]);
    setSelectedNote(newTempNote);
    setCurrentContent('');
    setLastSavedContent('');
  };

  const handleUpdateNote = async (noteId, updatedContent) => {
    if (!noteId) return;

    // Prevent concurrent saves
    if (isSaving) {
      console.log('Save operation already in progress');
      return;
    }

    setIsSaving(true);

    const note = notes.find(n => n.id === noteId);
    const sanitizedContent = DOMPurify.sanitize(updatedContent || '');
    const trimmedContent = sanitizedContent.trim();

    // Handle temp note
    if (note?.isTemp) {
      if (!trimmedContent) {
        setNotes(notes.filter(n => n.id !== noteId));
        setSelectedNote(null);
        setTempNote(null);
        setCurrentContent('');
        setLastSavedContent('');
        setIsSaving(false);
        return;
      }

      try {
        const newNote = {
          creatorId: currentUser.uid,
          creatorName: currentUser.displayName || 'Anonymous',
          createdAt: new Date(),
          updatedAt: [],
          documentType: 'note',
          content: trimmedContent,
          contentLastVersion: new Date(),
        };

        const docRef = collection(db, `documents/${currentUser.uid.slice(0, 16)}/docs`);
        const docSnap = await addDoc(docRef, newNote);

        const createdNote = { id: docSnap.id, ...newNote };

        // Update all relevant state atomically
        setNotes(prevNotes => prevNotes.map(n => n.id === noteId ? createdNote : n));
        setSelectedNote(createdNote);
        setTempNote(null);
        setCurrentContent(trimmedContent);
        setLastSavedContent(trimmedContent);

        setSnackbarMessage('Note created successfully.');
        setOpenSnackbar(true);
      } catch (error) {
        console.error('Error creating note:', error);
        setSnackbarMessage('Error creating note.');
        setOpenSnackbar(true);
      } finally {
        setIsSaving(false);
      }
      return;
    }

    // Handle existing note
    try {
      const noteRef = doc(db, `documents/${currentUser.uid.slice(0, 16)}/docs`, noteId);
      const updateData = {
        content: trimmedContent,
        updatedAt: [...(note?.updatedAt || []), new Date()],
        contentLastVersion: new Date()
      };

      await updateDoc(noteRef, updateData);

      // Update all relevant state atomically
      setNotes(prevNotes =>
        prevNotes.map(note => note.id === noteId ? { ...note, ...updateData } : note)
      );
      setSelectedNote(prev => prev?.id === noteId ? { ...prev, ...updateData } : prev);
      setCurrentContent(trimmedContent);
      setLastSavedContent(trimmedContent);

      setSnackbarMessage('Note updated successfully!');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating note:', error);
      setSnackbarMessage('Error updating note.');
      setOpenSnackbar(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleNoteChange = useCallback((content) => {
    const tagRegex = /#[a-zA-Z0-9]+/g;
    const matches = content.match(tagRegex);
    
    if (matches) {
      clearTimeout(tagCreationTimeoutRef.current);
      tagCreationTimeoutRef.current = setTimeout(async () => {
        const completeTags = matches.filter(tag => 
          // Only consider tags that are complete (followed by space or end of line)
          content.match(new RegExp(`${tag}(\\s|$)`))
        );
        
        for (const tag of completeTags) {
          if (!availableTags.find(t => t.label === tag)) {
            const tagsRef = collection(db, `documents/${currentUser.uid.slice(0, 16)}/tags`);
            await addDoc(tagsRef, { label: tag });
          }
        }
      }, 1000); // Wait 1 second after typing stops
    }
    
    setCurrentContent(content);
  }, [availableTags, currentUser.uid]);

  const handleDeleteNote = async () => {
    if (!selectedNote?.id) return;

    setIsSaving(true);
    try {
      const noteRef = doc(db, `documents/${currentUser.uid.slice(0, 16)}/docs`, selectedNote.id);
      await deleteDoc(noteRef);
      setNotes(notes.filter(note => note.id !== selectedNote.id));
      setSelectedNote(null);
      setCurrentContent('');
      setLastSavedContent('');
      setSnackbarMessage('Note deleted successfully!');
      setOpenSnackbar(true);
      setOpenAlert(false);
    } catch (error) {
      console.error('Error deleting note:', error);
      setSnackbarMessage('Error deleting note.');
      setOpenSnackbar(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSortClick = useCallback((event) => {
    setSortAnchorEl(event.currentTarget);
  }, []);

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortChange = (sortType) => {
    setSortBy(sortType);
    const sortedNotes = [...notes].sort((a, b) => {
      const dateA = a.createdAt instanceof Date ? a.createdAt : a.createdAt.toDate();
      const dateB = b.createdAt instanceof Date ? b.createdAt : b.createdAt.toDate();

      switch (sortType) {
        case 'newest':
          return dateB - dateA;
        case 'oldest':
          return dateA - dateB;
        case 'updated':
          const lastUpdateA = a.updatedAt?.length ? a.updatedAt[a.updatedAt.length - 1] : a.createdAt;
          const lastUpdateB = b.updatedAt?.length ? b.updatedAt[b.updatedAt.length - 1] : b.createdAt;
          return lastUpdateB - lastUpdateA;
        default:
          return dateB - dateA;
      }
    });
    setNotes(sortedNotes);
    handleSortClose();
  };

  // Handle tag updates
  const handleTagUpdate = async (updatedTags) => {
    // Update tags in Firebase
    const batch = db.batch();
    const tagsRef = collection(db, `documents/${currentUser.uid.slice(0, 16)}/tags`);

    // Delete removed tags
    const removedTags = availableTags.filter(tag =>
      !updatedTags.find(t => t.id === tag.id)
    );
    removedTags.forEach(tag => {
      const tagRef = doc(tagsRef, tag.id);
      batch.delete(tagRef);
    });

    // Add/update tags
    updatedTags.forEach(tag => {
      const tagRef = doc(tagsRef, tag.id);
      batch.set(tagRef, { label: tag.label });
    });

    await batch.commit();
    setAvailableTags(updatedTags);
  };

  // Update search to include tags
  const handleSearch = (query) => {
    setSearchQuery(query);
    const matchingTags = availableTags.filter(tag =>
      tag.label.toLowerCase().includes(query.toLowerCase())
    );
    setTagResults(matchingTags);
  };

  // Filter by tag
  const handleTagClick = (tag) => {
    setSearchQuery(`tag:${tag.label}`);
    setTagResults([]);
  };

  // In the NotesPage component, add this new function:
  const handleTagsChange = async (noteId, newTags) => {
    try {
      const noteRef = doc(db, `documents/${currentUser.uid.slice(0, 16)}/docs`, noteId);
      await updateDoc(noteRef, {
        tags: newTags.map(tag => tag.id)
      });

      // Update local state
      setNotes(prevNotes => prevNotes.map(note =>
        note.id === noteId
          ? { ...note, tags: newTags.map(tag => tag.id) }
          : note
      ));

      // If this is the selected note, update it too
      setSelectedNote(prev =>
        prev?.id === noteId
          ? { ...prev, tags: newTags.map(tag => tag.id) }
          : prev
      );

      setSnackbarMessage('Tags updated successfully!');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating tags:', error);
      setSnackbarMessage('Error updating tags.');
      setOpenSnackbar(true);
    }
  };

  // Update note filtering
  const filteredNotes = notes.filter(note => {
    if (searchQuery.startsWith('tag:')) {
      const tagLabel = searchQuery.slice(4).toLowerCase();
      return note.tags?.some(tagId => {
        const tag = availableTags.find(t => t.id === tagId);
        return tag?.label.toLowerCase() === tagLabel;
      });
    }

    const content = note.content?.toLowerCase() || '';
    const tagMatch = note.tags?.some(tagId => {
      const tag = availableTags.find(t => t.id === tagId);
      return tag?.label.toLowerCase().includes(searchQuery.toLowerCase());
    });
    return content.includes(searchQuery.toLowerCase()) || tagMatch;
  });

  const handleSearchChange = useCallback((value) => {
    setSearchQuery(value);
    if (value) {
      navigate(`/notes/${encodeURIComponent(value)}`, { replace: true });
    } else {
      // Only update URL if no note is selected
      if (!selectedNote) {
        navigate('/notes', { replace: true });
      }
    }
  }, [navigate, selectedNote]);

  const handleNoteSelect = useCallback((note) => {
    setSelectedNote(note);
    if (note) {
      navigate(`/notes/note=${note.id}`, { replace: true });
      setSearchQuery('');
    }
    if (isMobile) setMobileOpen(false);
  }, [isMobile, navigate]);

  const sidebarProps = {
    searchQuery,
    onSearchChange: handleSearchChange,
    onSortClick: handleSortClick,
    onAddNote: handleAddNote,
    filteredNotes,
    selectedNote,
    onNoteSelect: handleNoteSelect,
    isMobile
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  return (
    <Container maxWidth="false" sx={{ flex: 1 }}>
      {isMobile && (
        <Box>
          <IconButton edge="start" onClick={() => setMobileOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Box>
      )}

      <Box sx={{ display: 'flex', gap: 0, height: '100%' }}>
        {isMobile ? (
          <MobileSidebar
            open={mobileOpen}
            onClose={() => setMobileOpen(false)}
            sidebarProps={sidebarProps}
          />
        ) : (
          <Box sx={{
            width: `${sidebarWidth}px`,
            flexShrink: 0,
            overflow: 'auto',
            height: '100%'
          }}>
            <Sidebar {...sidebarProps} />
          </Box>
        )}

        {!isMobile && <Box
          sx={{
            width: '8px',
            cursor: 'col-resize',
            backgroundColor: isDragging ? 'action.active' : 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            transition: 'background-color .5s ease .3s',
            '&:hover': {
              backgroundColor: 'action.active'
            }
          }}
          onMouseDown={handleMouseDown}
        />}

        <Box sx={{ flex: 1, height: '100%', overflow: 'auto' }}>
          <NoteViewer
            note={selectedNote}
            handleUpdateNote={handleUpdateNote}
            handleNoteChange={handleNoteChange}
            handleDeleteNote={handleDeleteNote}
            tagManagerOpen={tagManagerOpen}
            setTagManagerOpen={setTagManagerOpen}
            handleTagUpdate={handleTagUpdate}
            spellCheckEnabled={spellCheckEnabled}
            isSaving={isSaving}
            availableTags={availableTags}
            onTagsChange={(newTags) => handleTagsChange(selectedNote?.id, newTags)}
            onSaveShortcut={(content) => {
              if (selectedNote && content !== lastSavedContent) {
                handleUpdateNote(selectedNote.id, content);
              }
            }}
          />

        </Box>
      </Box>

      <SortPopover
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
        onSortChange={handleSortChange}
      />

<TagManager
                    open={tagManagerOpen}
                    onClose={() => setTagManagerOpen(false)}
                    currentUserId={currentUser.uid}
                    availableTags={availableTags}
                    onTagsUpdate={setAvailableTags}
                    />

      <Dialog open={openAlert} onClose={() => setOpenAlert(false)}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {alertAction} this note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlert(false)}>Cancel</Button>
          <Button
            onClick={alertAction === 'delete' ? handleDeleteNote : null}
            color="primary"
          >
            {alertAction}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default NotesPage;